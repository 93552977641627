/* You can add global styles to this file, and also import other style files */

html, body {
    /* background-color: #f7f7f7 !important; */
      margin: 0px;
      min-height: 100%;
      height: 100%;
    }
    
    * {
      box-sizing: border-box;
      font-family: Roboto, Arial, sans-serif;
    }

.row{
    display: flex;
    flex-wrap: wrap;
}
.col-1{
    width: 8.33%;
}
.col-2{
    width: 16.66%;
}
.col-3{
    width: 25%;
}
.col-4{
    width: 33.33%;
}
.col-5{
    width: 41.66%;
}
.col-6{
    width: 50%;
}
.col-7{
    width: 58.33%;
}
.col-8{
    width: 66.66%;
}
.col-9{
    width: 75%;
}
.col-10{
    width: 83.33%;
}
.col-11{
    width: 91.66%;
}
.col-12{
    width: 100%;
}

.marg-bot-15{
    margin-bottom: 15px;
}

.marg-padding-7 {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #ebebeb;
}

.margin-left-5 {
    margin-left: 5px;
}

.marg-bot-5 {
    margin-bottom: 5px;
}

.marg-left-20 {
    margin-left: 20px;
}



.container-fluid {
    padding: 5px;
    margin: 10px 10px 0;
    border: 1px solid rgba(221, 221, 221, 1);
}

.container-fluid-tabs {
    margin: 10px 10px 0;
}

.calendar-container {
   padding: 10px 10px 0;
    margin-right: auto;
    margin-left: auto;
}

.calendar {
    width: calc(100% - 245px);
    height: calc(100vh - 91px);
    margin-left: 10px;
}
.subnav-header{
    text-align: center;
}
.subnav-header ul{
    margin: 10px 0 !important;
    display: inline-flex;
    list-style: none;
    text-align: right;
}
.subnav-header ul li{
    padding: 10px;
    cursor: pointer;
}
.subnav-header ul li:hover{
    color:#337ab7;
}
.menu-active{
    color:#337ab7;
}


.removeButton {
    border: 0;
    background-color: transparent;
}

.consultationTabs {
    height: calc(100% + 10px) !important;
}

.consultationTabs .dx-form .dx-list .dx-list-item {
    border: 0px;
    width: 33%;
    float: left;
}

.appRoles .dx-form .dx-list .dx-list-item {
    border: 0px;
    width: 33%;
    float: left;
}

.dx-form {
    margin: 5px 0 0 5px;
}

 
 .dx-row.dx-column-lines.dx-virtual-row { /* to not display the visual bug at the end of the grid */
    display: none;
}

.consultationTabs dx-tab-panel {
    height: 100%;
}

.procList {
    min-height: 50px;
    /* height: calc(80vh - 475px); */
}

.row h2 {
    margin-top: 10px;
}
.adnotes{
    margin-left: 9px;
}

.dropdownTemplate {
    border-left: 10px solid;
}

.dropdownTemplate div {
    margin-left: 10px;
}

.master-detail-caption {
    padding: 0 0 5px 10px;
    font-size: 14px;
    font-weight: bold;
}

.subGridContainer {
    min-height: 200px;
}

.dx-popup-content .dx-item.dx-box-item .dx-calendar-view-month {
    display: none;
}
/* 
.dx-popup-content .dx-item.dx-box-item .dx-timeview-clock {
    display: none !important;
} */

.accordion {
    width: 20%;
    height: calc(100% - 30px);
    border-bottom: 1px solid rgba(221, 221, 221, 1);
}

.patient-hr-line {
    border-color: rgba(221, 221, 221, 0);
    background-color: rgba(221, 221, 221, 1);
    color: rgba(221, 221, 221, 1);
}

.disable {
   background-color:rgb(208, 208, 208);
    opacity: 0.3;
}

.closeButton {
    right: 10px;
    top: 2px;
    cursor: pointer;
    position: absolute;
}

.closeButton dx-button {
    border:none;
}

.fullScreenButton {
    right: 5px;
    position: absolute;
}

.fullScreenButton dx-button {
    border: none;
}

.popupHeader{
    height: 30px;
}

.popupImage {
    float:left; 
    margin: 3px 0px;
    display:block;
}

.popupImage img{
    height: 18px;
}

.popupText {
    margin: 3px 0 0 10px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
}

.historyDetail {
    margin-top: 10px;
}

.hideInfo {
    display: none;
}

.updatePatient {
    cursor: pointer;
    font-weight: bold;
    padding: 2px;
    
}

dx-scheduler .dx-scheduler-navigator-caption.dx-button-normal.dx-widget {
    pointer-events: none;
}


/* .dx-scheduler-date-table-cell {
    height: 50px;
}
.dx-scheduler-time-panel-cell {
    height:  50px;
}
.dx-scheduler-time-panel {
    margin-top: -50px;
} */

#toolbar {
    position: fixed;
    bottom: 1px;
    height: 31px;
    width: 100%;
    border-top: thin solid rgba(221, 221, 221, 1);
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #fff;
}

.procQuantity {
    height: 20px;
    width: 100px;
    margin-top: -1px;
    border-radius: 4px;
    border: 1px solid gainsboro;
}

.procQuantity input {
    min-height: 20px !important;
}

.last-button {
    margin-bottom: 35px;
}

.actions {
    width: 232px;
}

.custom {
   text-decoration: none !important;
   font-size: 18px !important;
   margin-right: 5px !important;
}


.desktopView {
    display: flex;
    height: calc(100% - 5px);
}

.calendar dx-scheduler {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 650px) {

    .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit{
        width: 60px;
        min-width: 60px;
    }

    .logout {
        padding: 5px 20px 0px 20px !important;
    }

    .consultationTabItem {
        height: 100%;
    }


    .calendar-container .row {
        height: 85vh;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .col-9 .menu {
        padding-right: 0 !important;
    }

    .calendar {
        width: 92vw;
        height: calc(100% - 200px);
        margin: 0;
    }

    .calendar dx-scheduler {
        height: 100%;
        width: 100%;
        order: 2 
    }

    .calendar.isDoc {
        height: calc(100% - 100px);
    }

    .row .actions {
         order: 1;
         width: 95vw;
    }

    dx-scheduler .dx-scheduler-navigator-caption.dx-button-normal.dx-widget {
        pointer-events: auto;
    }

    .row .actions dx-calendar {
        display: none;
    }

   
    .row .actions .options .selectList {
        display: inline-block !important;
        width: calc(50vw - 20px);
        height: calc(15vh - 10px);
        border: none;
    }

    .row .actions .options .selectList label{
        font-size: 14px;
        font-weight: 500;
    }

    .selectList dx-list {
        height: 75px;
    }

    .actionButtons {
        text-align: center;
    }

    .actionButtons .marg-bot-5, .actionButtons .marg-left-20 {
        margin: 5px 10px 0 0;
        display: inline-block;
        width: calc(25vw - 20px);

    }

    .actionButtons dx-button .dx-button-text {
        display: none;
    }

    .mobileView {
        height: auto;
        width: 100%;
        position: absolute;
        bottom: 0;
        top: 35px; 
    }

    .slideout-container {
        width: calc(90vw - 65px);
    }

    .patientHistory {
        margin-top: 10px;
    }

    .mobileView dx-toolbar .dx-button.dx-button-normal.dx-widget.dx-button-has-icon {
        width: 40px;
        border: none;
        margin-top: 5px;
    }

    .mobileView dx-toolbar .dx-button.dx-button-normal.dx-widget.dx-button-has-icon .dx-icon.dx-icon-menu {
        font-size: 20px;
    }

    .slideout-container dx-accordion {
        margin-top: 4px;
    }

    .consultationTabs .dx-form .dx-list .dx-list-item {
        border: 0px;
        width: 100%;
        float: left;
    }

    #focusOutside {
        margin-top: 10px;
        position: absolute;
    }
}


.userDataGrid a.dx-icon-doc, .userDataGrid a.dx-icon-group{
    font-size: 18px !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item-content {
    /* for default popup opening prevention */
    padding: 0 !important;
}

.appointment-tooltip {
    font-size: 15px;
    cursor: auto;
    padding: 5px
}

.custom-text-box{
    width: 100%;
    display:inline-block;
  }

  .custom-button {
    position: absolute;
    border-style: none;
    right: 0px;
    top: 0px;
    vertical-align: initial;
  }

  .patient-details span {
      display: block;
      margin: 2px 0;
  }

  .patient-container {
    border-bottom: 1px solid rgba(221, 221, 221, 1);
  }

    dx-data-grid .dx-freespace-row {
      display: none;
  }

  .outside-schedule-text {
      color: red;
      text-decoration-style: dotted;
  }

  .cons-file-preview {
     width: calc(80% - 40px);
     height: 100%;
     display: flex;
  }

  .custom-tab {
      margin: 5px;
      display: flex !important;
      width: calc(100% - 10px) !important;
      height: calc(100% - 10px) !important;
  }

  .action-buttons {
      display: block;
      position: absolute;
      right: 53px;
      bottom: 10px;
      width: 30px;
  }

  .action-buttons dx-button{
    margin: 5px 0;
  }

  .layout-header {
    z-index: 1501;
    display: block;
  } 

  .calendar-tabs-bottom dx-tab-panel {
    position: absolute;
    bottom: 49px;
    left: 10px;
    max-width: 232px;
  }

  .calendar-tabs-bottom .dx-tabpanel .dx-tab {
      width: 116px !important;
  }

  /* login css */

  #loginModal{
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 9000;
    
}


.login-box{
    width: 230px;
    /* transform: scale(1.2); */
    margin: 25px auto 0;
    /* height: 270px; */
}

.login-box .logo img {
    width: 100%;
}

.logo {
    text-align: center;
    margin-bottom: 20px;
}

.logo-text {
    font-size: 25px;
    font-weight: 500;
}

.login-modal {
    width: 360px;
    margin: auto auto;
    padding: 40px;
  }

  .login-footer {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
  }

/* .imgBox > #logoImg{
    width: 50vw;
    height: 11vw;
    margin-left: -10vw !important;
}

#logoImg {
    display: none !important;
    background-image: url("./assets/img/logo.png");
    position: relative;
    margin: 15px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}

#loginBox.isLoginVisible #logoImg{
    width: 301px;
    height: 80px;
    transition: all .7s ease-in-out;
    -webkit-transition: all .7s ease-in-out;
    margin-left: 0px !important;
} */

#loginBox.isLoginVisible {
    top: calc(50vh - 200px) !important;
    left: calc(50vw - 150px) !important;
}

#btLogin {
    background: #253648; 
}

.register-link a {
    color: #253648 !important;
    font-weight: bold;
}

.loginInput {
    background: transparent !important;
    border-bottom-color: #253648 !important;
    border-right-color: #253648 !important;
}

.userGrid td.dx-command-edit.dx-command-edit-with-icons { 
    display: flex;
  }

.dx-toolbar-center {
    margin: 0px auto !important;
    float: none !important;
}

div.dx-drawer-panel-content.dx-overlay.dx-widget.dx-visibility-change-handler > div {
    /* important pentru visual bug pe asistent, doctor view */
    width: 100% !important;
    height: 100% !important;
}

.form-card {
    width: 100%;
    height: 100%;
}



