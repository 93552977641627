.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content-block {
  padding: 0 10px;

  .screen-x-small & {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.content-block.footer {
  margin-top: 10px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.full-height-scrollable > div > div >.dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

 

